<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF jE mb-4">
			<div class="dF">
				<div>
					<a-input ref="searchInput" v-model="searchQuery" placeholder="Search here..."
						style="width: 300px" size="large">
						<a-icon slot="prefix" type="search" />
					</a-input>
				</div>
			</div>
		</div>
		<a-card style="padding: 0" class="table-card">
			<a-table class="tableStyle" :columns="columns" :data-source="records"
				:row-key="(record) => record.transaction.id">
				<div slot="transactionDate" slot-scope="record">
					{{ record.transaction?.createdAt && moment(record.transaction.createdAt).format('ll') }}
				</div>
				<div slot="agent" slot-scope="record">
					<div>
						Name: {{ record.agent.firstName + " " + record.agent.lastName }}
					</div>
					<div>
						Email: {{ record.agent.email }}
					</div>
					<div v-if="record.agent.phone">
						Phone: {{ record.agent.phone }}
					</div>
					<div v-if="record.agent.company">
						Company: {{ record.agent.company }}
					</div>
				</div>
				<div slot="payoutType" slot-scope="record" class="dF fC">
					<div v-for="(p, i) in record.payouts" :key="i">
						<span v-if="p.stage">
							Stage: {{ p.stage }}
						</span>
						<span v-else>
							Date: {{ moment(p.date).format('ll') }}
						</span>
					</div>
				</div>
				<div slot="payoutAmount" slot-scope="record" class="dF fC">
					<div v-for="(p, i) in record.payouts" :key="i">
						${{ $formatNumber(p.amount) }}
					</div>
				</div>
				<div slot="payoutDate" slot-scope="record" class="dF fC">
					<div v-for="(p, i) in record.payouts" :key="i">
						{{ moment(p.date).format('ll') }}
					</div>
				</div>
				<div slot="actions" slot-scope="record">
					<a-button v-if="record.transaction" @click="openTransactionDetails(record.transaction)">View
						Details</a-button>
				</div>
			</a-table>
		</a-card>
	</div>
</template>

<script>
	import moment from "moment";
	import { formatDate } from "bh-mod";

	export default {
		components: {},
		watch: {},
		data() {
			return {
				searchQuery: "",
				columns: [
					{
						title: "Worksheet Id",
						dataIndex: "worksheet.readableId",
						key: "worksheet",
						sorter: (a, b) =>
							a.worksheet.readableId < b.worksheet.readableId
								? -1
								: a.worksheet.readableId > b.worksheet.readableId
									? 1
									: 0,
					},
					{
						title: "Transaction Product",
						dataIndex: "transaction.productName",
						key: "transaction",
						sorter: (a, b) =>
							a.transaction.productName < b.transaction.productName
								? -1
								: a.transaction.productName > b.transaction.productName
									? 1
									: 0,
					},
					{
						title: "Transaction Date",
						key: "transactionDate",
						scopedSlots: { customRender: "transactionDate" },
						sorter: (a, b) =>
							moment(a.transaction.createdAt).format("x") -
							moment(b.transaction.createdAt).format("x"),
					},
					{
						title: "Agent",
						key: "agent",
						scopedSlots: { customRender: "agent" },
						sorter: (a, b) => {
							const aa = a.agent.firstName + a.agent.lastName;
							const bb = b.agent.firstName + b.agent.lastName;
							return aa < bb ? -1 : aa > bb ? 1 : 0
						}
					},
					{
						title: "Payout Type",
						key: "payoutType",
						scopedSlots: { customRender: "payoutType" },
					},
					{
						title: "Payout Amount",
						key: "payoutAmount",
						scopedSlots: { customRender: "payoutAmount" },
					},
					{
						title: "Payout Date",
						key: "payoutDate",
						scopedSlots: { customRender: "payoutDate" },
					},
					{
						title: "",
						scopedSlots: { customRender: "actions" },
						key: "actions",
					},
				]
			};
		},
		computed: {
			instance() {
				return this.$store.state.instance;
			},
			commissionPayouts() {
				return this.$store.state.reports.commissionPayouts;
			},
			records() {
				let result = this.commissionPayouts;
				if (this.searchQuery) {
					let query = this.searchQuery.toLowerCase().trim();
					result = result.filter((item) => {
						return (
							item.worksheet.readableId?.toLowerCase().includes(query) ||
							item.transaction.productName?.toLowerCase().includes(query) ||
							item.agent.firstName?.toLowerCase().includes(query) ||
							item.agent.lastName?.toLowerCase().includes(query) ||
							item.agent.email?.toLowerCase().includes(query) ||
							item.agent.phone?.toLowerCase().includes(query) ||
							item.agent.company?.toLowerCase().includes(query)
						);
					});
				}
				return result;
			},
		},
		methods: {
			formatDate, moment,

			trim(str, len) {
				if (str.length > len) {
					return str.substring(0, len) + "...";
				} else {
					return str;
				}
			},

			openTransactionDetails(transaction) {
				window.open(`https://transactions.bildhive.${this.$tld}/transaction/${transaction.id}?tab=details`, '_blank');
			}
		},
		created() {
			this.$store.dispatch("GET_COMMISSION_PAYOUTS");
		},
	};
</script>

<style scoped></style>
